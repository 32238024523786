.results {
    border-color: black;
    background-color: lightgrey;
    border-width: .1em;
    display: flex;
    margin: auto;
    font-size: 1.3rem;
    min-width: min-content;
    max-width: max-content;
    border-style: solid;
}

.results-content {
    border-width: 1rem;
    display: flex;
    margin: auto;
    font-size: 1.3rem;
    min-width: min-content;
    max-width: max-content;
    max-width: 100%;
    border-style: solid;
    border-color: lightgrey;
}
.ip-input {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}

.ip {
    border-color: black;
    background-color: lightgrey;
    size: 10;
}
.cidr {
    border-color: black;
    background-color: lightgrey;
    size: 1;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* Safari, Chrome */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}